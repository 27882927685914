<template>
  <v-container>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="deleteConfirm = -1;"
      @handelRequest="removeMessage"
    ></confirm-delete>
    <v-layout row align-center justify-center class="pa-6 mb-8">
      <p class="display-3 main-color mx-5">{{$t('messages.messages')}}</p>
      <SearchMessages @search="searchMessages" />
    </v-layout>
    <v-progress-linear
      v-show="loading"
      indeterminate
      :color="this.primaryColor"
    ></v-progress-linear>
   <Message v-for="message in this.getMessages" :key="message.id" @delete="deleteConfirm = message.id" :name="message.name" :email="message.email" :note="message.note" :createdAt="message.createdAt" :id="message.id" /> 
  </v-container>
  
</template>
<script>
import SearchMessages from './Components/SearchMessages'
import Message from './Components/Message'
import ConfirmDelete from "../../Components/confirmDelete";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Messages',
  components: {
    SearchMessages,
    Message,
    ConfirmDelete
  },
  data () {
    return {
      deleteConfirm: -1,
      loading: false
    }
  },
  created () {
    this.loading = true;
    this.fetchContactMessages().then(() => {
      this.loading = false;
    });
  },
  methods: {
    ...mapActions("ContactUsAdmin", ["fetchContactMessages", "fetchContactMessagesWithSearch", "deleteMessage"]),

    searchMessages (payload) {
      this.fetchContactMessagesWithSearch(payload);
    },

    removeMessage (payload) {
      this.deleteConfirm = -1;
      this.deleteMessage({id: payload.id});
    },
  },
  computed: mapGetters("ContactUsAdmin", ["getMessages"])
}
</script>