<template>
  <form @submit="$emit('search', { searchTerm: searchTerm })" uk-grid class="uk-search uk-search-default ml-5" lazy-validation v-on:submit.prevent>
    <div class="uk-width-auto uk-position-relative">
      <span class="uk-search-icon-flip" :style="{'color':this.primaryColor }" uk-search-icon></span>
      <input v-model="searchTerm" class="uk-search-input" type="search" :placeholder="$t('navBar.search')">
    </div>
  </form>
</template>
<script>
export default {
  name: "SearchMessages",
  data () {
    return {
      searchTerm: ''
    }
  }
}
</script>